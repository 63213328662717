.logo {
  max-width: 30vw;
}
@include media-breakpoint-up(md) {
  .logo {
    max-width: 18vw;
  }
}

@include media-breakpoint-up(lg) {
  .logo {
    max-width: 16vw;
  }
}

@include media-breakpoint-up(xl) {
  .logo {
    max-width: 14vw;
  }
}

@include media-breakpoint-up(xxl) {
  .logo {
    max-width: 10vw;
  }
}

.logo-sm {
  width: 8em;
}

@include media-breakpoint-up(lg) {
  #home {
    background-image: url(/_img/chaos-simple.svg);
    background-repeat: no-repeat;
    background-size: 90vw;
    background-position: 50% 50%;
  }
}

.healthcare,
.student {
  max-height: 350px;
}
.authentication,
.construction {
  //max-width: 400px;
}
