.layout-default {
  display: grid;
  grid-gap: 0;
  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-template-areas:
    'header'
    'main'
    'footer';
}

.header {
  grid-area: header;
}
.main {
  grid-area: main;
}
.footer {
  grid-area: footer;
}
