// General typography
h2,
h3,
h4,
h5,
h6 {
  margin: $spacer * 1.5 0 $spacer * 0.5;
  max-width: 80ch;
  text-wrap: balance;
}
.graident {
  background-image: radial-gradient(
    farthest-corner circle at 100% 0%,
    #23d1ff -25%,
    #590078 170%
  );
  background-image: radial-gradient(
    farthest-corner circle at 100% 0% in oklab,
    oklch(80% 0.4 222) -25%,
    oklch(35% 0.5 313) 170%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
p {
  margin-top: $spacer * 0.5;
  margin-bottom: $spacer * 0.5;
}
ul,
ol {
  margin-top: $spacer;
  margin-bottom: $spacer;
}
li {
  ul,
  ol {
    margin-top: 0;
    margin-bottom: 0;
  }
  margin-top: $spacer * 0.4;
  margin-bottom: $spacer * 0.4;
}
ul.list-inline,
ul.navbar-nav {
  margin-top: 0;
  margin-bottom: 0;
  .list-inline-item {
    margin: $spacer * 0.3;
  }
  .list-inline-item:first-of-type {
    margin-left: 0;
  }
  .list-inline-item:last-of-type {
    margin-right: 0;
  }
}

.list-group li {
  margin-top: 0;
  margin-bottom: 0;
  padding: $spacer * 0.5;
}

.micro {
  @include font-size($initialism-font-size);
}

.lead {
  text-wrap: balance;
}

// Nofollow links
a[rel='nofollow noopener']:not(.stretched-link):after {
  font: var(--fa-font-regular);
  content: '\f08e';
  font-size: 0;
}

a[rel='nofollow noopener'] .svg-inline--fa {
  font-size: 0.85;
  margin-left: 0.35;
  margin-right: 0.25;
  opacity: 0.75;
}

a[rel='nofollow noopener'] .svg-inline--fa:after {
  content: '';
}

// resets top margin on any first elements in the first instance of the vw-default content area or the main element in nav layouts and the aside element
.layout-default .vw-container > .vw-default > :first-child {
  margin-top: 0;
}
.layout-nav .main > :first-child {
  margin-top: 0;
}
.aside > .wrapper > :first-child {
  margin-top: $spacer * 0.5;
}

// resets margins on alerts
.alert > :first-child {
  margin-top: 0;
}
.alert > :last-child {
  margin-bottom: 0;
}

// manual controls for line length
$line-lengths: 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120;
@each $line-length in $line-lengths {
  .mw-#{$line-length} {
    max-width: $line-length + ch;
  }
}
