.gradient-light {
  background-image: radial-gradient(
    farthest-corner circle at 50% 0%,
    #ffffff 50%,
    #7209b7 500%
  );
}
.gradient-hdr-logo {
  background-image: radial-gradient(
    farthest-corner circle at 100% 0%,
    #23d1ff 0%,
    #590078 200%
  );
  background-image: radial-gradient(
    farthest-corner circle at 100% 0% in oklab,
    oklch(80% 0.4 250) 50%,
    oklch(35% 0.5 300) 200%
  );
}
.gradient-hdr {
  background-image: radial-gradient(
    farthest-corner circle at 100% 0%,
    #23d1ff 0%,
    #590078 100%
  );
  background-image: radial-gradient(
    farthest-corner circle at 100% 0% in oklab,
    oklch(80% 0.4 250) 50%,
    oklch(35% 0.5 300) 100%
  );
}
.gradient-hdr-reverse {
  background-image: radial-gradient(
    farthest-corner circle at 100% 100%,
    #23d1ff 0%,
    #590078 100%
  );
  background-image: radial-gradient(
    farthest-corner circle at 100% 100% in oklab,
    oklch(80% 0.4 250) 50%,
    oklch(35% 0.5 300) 100%
  );
}
