$blue: #4895ef;
$teal: #4cc9f0;
$indigo: #3a0ca3;
$purple: #7209b7;
$red: #f72585;
$orange: #ffa500;
$green: #0d890d;

$secondary: $indigo;
$info: $teal;

$enable-rounded: false;
$enable-cssgrid: true;
$enable-negative-margins: true;

$spacer: 2.5vmin;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 5,
  7: $spacer * 8,
  8: $spacer * 10,
  9: $spacer * 15,
  10: $spacer * 20,
);

$position-values: (
  0: 0,
  25: 25%,
  50: 50%,
  75: 75%,
  100: 100%,
);

//$link-decoration: none;
$link-shade-percentage: 50%;

$font-size-base: 1rem;

$aspect-ratios: (
  '1x1': 100%,
  '2x1': calc(1 / 2 * 100%),
  '4x3': calc(3 / 4 * 100%),
  '16x9': calc(9 / 16 * 100%),
  '21x9': calc(9 / 21 * 100%),
);

$rfs-base-value: 0.875rem;
$rfs-factor: 200;
$rfs-rem-value: 16;

$line-height-base: 1.4;

$h1-font-size: $font-size-base * 4.209;
$h2-font-size: $font-size-base * 3.157;
$h3-font-size: $font-size-base * 2.369;
$h4-font-size: $font-size-base * 1.777;
$h5-font-size: $font-size-base * 1.333;

$headings-font-weight: 700;
$headings-line-height: 1.125;

$display-font-sizes: (
  1: $font-size-base * 5.61,
  2: $font-size-base * 4.209,
  3: $font-size-base * 3.157,
  4: $font-size-base * 2.369,
  5: $font-size-base * 1.777,
  6: $font-size-base * 1.333,
);

$display-line-height: $headings-line-height * 0.9;

$lead-font-size: $font-size-base * 1.333;
$lead-font-weight: 400;

$blockquote-margin-y: 0;
$blockquote-font-size: unset;
$blockquote-footer-font-size: $font-size-base * 0.875;
