/*
 * See: https://getbootstrap.com/docs/5.3/customize/sass/
 */

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import '../../node_modules/bootstrap/scss/functions';

// 2. Include any default variable overrides here
@import 'theme-variables';

// 3. Include remainder of required Bootstrap stylesheets (including any separate color mode stylesheets)
@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/variables-dark';

// 4. Include any default map overrides here

// 5. Include remainder of required parts
@import '../../node_modules/bootstrap/scss/maps';
@import '../../node_modules/bootstrap/scss/mixins';
@import '../../node_modules/bootstrap/scss/root';

// 6. Optionally include any other parts as needed
@import '../../node_modules/bootstrap/scss/utilities';

@import '../../node_modules/bootstrap/scss/reboot';
@import '../../node_modules/bootstrap/scss/type';
@import '../../node_modules/bootstrap/scss/images';
@import '../../node_modules/bootstrap/scss/containers';
@import '../../node_modules/bootstrap/scss/grid';
//@import '../../node_modules/bootstrap/scss/tables';
@import '../../node_modules/bootstrap/scss/forms';
@import '../../node_modules/bootstrap/scss/buttons';
//@import '../../node_modules/bootstrap/scss/transitions';
//@import '../../node_modules/bootstrap/scss/dropdown';
//@import '../../node_modules/bootstrap/scss/button-group';
//@import '../../node_modules/bootstrap/scss/nav';
//@import '../../node_modules/bootstrap/scss/navbar';
//@import '../../node_modules/bootstrap/scss/card';
//@import '../../node_modules/bootstrap/scss/accordion';
//@import '../../node_modules/bootstrap/scss/breadcrumb';
//@import '../../node_modules/bootstrap/scss/pagination';
//@import '../../node_modules/bootstrap/scss/badge';
//@import '../../node_modules/bootstrap/scss/alert';
//@import '../../node_modules/bootstrap/scss/progress';
//@import '../../node_modules/bootstrap/scss/list-group';
//@import '../../node_modules/bootstrap/scss/close';
//@import '../../node_modules/bootstrap/scss/toasts';
//@import '../../node_modules/bootstrap/scss/modal';
//@import '../../node_modules/bootstrap/scss/tooltip';
//@import '../../node_modules/bootstrap/scss/popover';
//@import '../../node_modules/bootstrap/scss/carousel';
//@import '../../node_modules/bootstrap/scss/spinners';
//@import '../../node_modules/bootstrap/scss/offcanvas';
//@import '../../node_modules/bootstrap/scss/placeholders';

@import '../../node_modules/bootstrap/scss/helpers';

// 6.5. map-merges
$utilities: map-merge(
  $utilities,
  (
    'background-color':
      map-merge(
        map-get($utilities, 'background-color'),
        (
          responsive: true,
        )
      ),
    'font-size':
      map-merge(
        map-get($utilities, 'font-size'),
        (
          responsive: true,
        )
      ),
    'width':
      map-merge(
        map-get($utilities, 'width'),
        (
          responsive: true,
        )
      ),
    'border':
      map-merge(
        map-get($utilities, 'border'),
        (
          responsive: true,
        )
      ),
    'border-top':
      map-merge(
        map-get($utilities, 'border-top'),
        (
          responsive: true,
        )
      ),
    'border-end':
      map-merge(
        map-get($utilities, 'border-end'),
        (
          responsive: true,
        )
      ),
    'border-bottom':
      map-merge(
        map-get($utilities, 'border-bottom'),
        (
          responsive: true,
        )
      ),
    'border-start':
      map-merge(
        map-get($utilities, 'border-start'),
        (
          responsive: true,
        )
      ),
  )
);

$utilities: map-merge(
  $utilities,
  (
    'viewport-height':
      map-merge(
        map-get($utilities, 'viewport-height'),
        (
          values:
            map-merge(
              map-get(map-get($utilities, 'viewport-height'), 'values'),
              (
                10: 10vh,
                20: 20vh,
                30: 30vh,
                40: 40vh,
                50: 50vh,
                60: 60vh,
                70: 70vh,
                80: 80vh,
                90: 90vh,
              )
            ),
        )
      ),
  )
);

$utilities: map-merge(
  $utilities,
  (
    'opacity':
      map-merge(
        map-get($utilities, 'opacity'),
        (
          values:
            map-merge(
              map-get(map-get($utilities, 'opacity'), 'values'),
              (
                10: 0.1,
                20: 0.2,
                30: 0.3,
                40: 0.4,
                60: 0.6,
                70: 0.7,
                80: 0.8,
                90: 0.9,
              )
            ),
        )
      ),
  )
);

$utilities: map-merge(
  $utilities,
  (
    'bg-opacity':
      map-merge(
        map-get($utilities, 'bg-opacity'),
        (
          values:
            map-merge(
              map-get(map-get($utilities, 'bg-opacity'), 'values'),
              (
                10: 0.1,
                20: 0.2,
                30: 0.3,
                40: 0.4,
                60: 0.6,
                70: 0.7,
                80: 0.8,
                90: 0.9,
              )
            ),
        )
      ),
  )
);

// Generate tints and shades
$all-colors: map-merge-multiple(
  $blues,
  $indigos,
  $purples,
  $reds,
  $oranges,
  $greens
);

$utilities: map-merge(
  $utilities,
  (
    'color':
      map-merge(
        map-get($utilities, 'color'),
        (
          values:
            map-merge(
              map-get(map-get($utilities, 'color'), 'values'),
              ($all-colors)
            ),
        )
      ),
  )
);

$utilities: map-merge(
  $utilities,
  (
    'background-color':
      map-merge(
        map-get($utilities, 'background-color'),
        (
          values:
            map-merge(
              map-get(map-get($utilities, 'background-color'), 'values'),
              ($all-colors)
            ),
        )
      ),
  )
);

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import '../../node_modules/bootstrap/scss/utilities/api';

// 8. Add additional custom code here
@import 'theme/dev';
@import 'theme/layout';
@import 'theme/typography';
// utilities
@import 'theme/utils';
