// Dev
// show active BP
.breakpoint::after {
  content: "XS";
}

@include media-breakpoint-up(sm) {
  .breakpoint::after {
    content: "SM";
  }
}

@include media-breakpoint-up(md) {
  .breakpoint::after {
    content: "MD";
  }
}

@include media-breakpoint-up(lg) {
  .breakpoint::after {
    content: "LG";
  }
}

@include media-breakpoint-up(xl) {
  .breakpoint::after {
    content: "XL";
  }
}

@include media-breakpoint-up(xxl) {
  .breakpoint::after {
    content: "XXL";
  }
}

.breakpoint {
  display: none;
}
